<template>
    <header class="header">
        <!--start navbar-->
        <nav
                class="navbar navbar-expand-lg fixed-top"
                v-bind:class="{
                affix: hasAffix,
                'custom-nav': coloredLogo,
                'bg-transparent': !coloredLogo,
                'white-bg': coloredLogo,
            }"
        >
            <div class="container">
                <a class="navbar-brand" href="/"
                ><img
                        :src="
                            coloredLogo
                                ? 'img/logo-color-1x.png'
                                : 'img/logo-white-1x.png'
                        "
                        width="180"
                        alt="logo"
                        class="img-fluid"
                /></a>

                <button
                        class="navbar-toggler"
                        type="button"
                        @click="mobileNavClicked"
                        v-bind:class="{ collapsed: collapsed }"
                >
                    <span class="ti-menu"></span>
                </button>

                <div
                        class="collapse navbar-collapse main-menu h-auto"
                        v-bind:class="{ show: !collapsed }"
                        id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <router-link
                                    class="nav-link page-scroll"
                                    to="/"
                            >Home</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                    class="nav-link page-scroll"
                                    :to="{ name: 'home', hash: '#about' }"
                                    v-scroll-to="'#about'"
                            >About</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                    class="nav-link page-scroll"
                                    :to="{ name: 'home', hash: '#features' }"
                                    v-scroll-to="'#features'"
                            >Features</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                    class="nav-link page-scroll"
                                    :to="{ name: 'home', hash: '#contact' }"
                                    v-scroll-to="'#contact'"
                            >Contact</router-link>
                        </li>
                    </ul>
                    
                </div>
            </div>
        </nav>
        <!--end navbar-->
    </header>
</template>

<script>
    export default {
        props: {
            coloredLogo: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                windowTop: 0,
                collapsed: true,
            };
        },
        mounted() {
            window.addEventListener("scroll", this.onScroll);
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.onScroll);
        },
        methods: {
            onScroll: function () {
                this.windowTop = window.top.scrollY;
            },
            mobileNavClicked: function () {
                this.collapsed = !this.collapsed;
            },
        },
        computed: {
            hasAffix: function () {
                return this.windowTop > 0;
            },
        },
    };
</script>

