<template>
    <section
        class="hero-section ptb-100 background-img"
        style="
            background: url('img/background-img-2.jpg') no-repeat center center / cover;
        "
    >
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-md-9 col-lg-7">
                    <div
                        class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0"
                    >
                        <h1 class="text-white mb-0">End-User License Agreement</h1>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "BannerService",
};
</script>

<style>
</style>