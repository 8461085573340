<template>
    <footer class="footer-section">
        <div class="bg-footer-custom footer-top pt-150 pb-5 background-img-2">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-lg-3 mb-3 mb-lg-0">
                        <div class="footer-nav-wrap text-white">
                            <img
                                src="img/logo-white-1x.png"
                                alt="footer logo"
                                class="img-fluid mb-3"
                                width="120"
                            />
                            <p>
                                InquestPro for JIRA<br/>Improved productivity, cost savings and faster time to market.
                            </p>


                        </div>
                    </div>
                    <div class="col-lg-3">

                </div>
                    <div class="col-lg-3 ml-auto mb-4 mb-lg-0">
                        <div class="footer-nav-wrap text-white">
                            <h5 class="mb-3 text-white">Links</h5>
                            <ul class="list-unstyled">
                                <li class="mb-2"><router-link to="Eula">EULA</router-link></li>
                                <li class="mb-2"><router-link to="Privacy">Privacy</router-link></li>
                                <li class="mb-2"><a href="https://inquestpro.atlassian.net">Support</a></li>
                                <li class="mb-2"><a href="https://docs.inquestpro.com">Docs</a></li>
                         
                            </ul>
                             
                        </div>
                       
                    </div>

                    <div class="col-lg-3 ml-auto mb-4 mb-lg-0">
                        <div class="footer-nav-wrap text-white">
                            <h5 class="mb-3 text-white">Support</h5>
                            <ul class="list-unstyled support-list">
                                <li class="mb-2 d-flex align-items-center">
                                    <span class="ti-location-pin mr-2"></span>
                                    EbizNeeds
                                    <br /> S-23, Krishna Marg, Saket Nagar, Shyam Nagar, Jaipur, Rajasthan, India
                                </li>
                                <li class="mb-2 d-flex align-items-center">
                                    <span class="ti-mobile mr2"></span>+91 141 408 2019
                                </li>
                                <li class="mb-2 d-flex align-items-center">
                                    <span class="ti-email mr-2"></span
                                    ><a href="php/support.php">
                                    &#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#105;&#110;&#113;&#117;&#101;&#115;&#116;&#112;&#114;&#111;&#046;&#099;&#111;&#109;</a
                                    >
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3">

                    </div>
                </div>
            </div>
        </div>

        <div class="footer-bottom gray-light-bg pt-4 pb-4">
            <div class="container">
                <div class="row text-center justify-content-center">
                    <div class="col-md-6 col-lg-5">
                        <p class="copyright-text pb-0 mb-0">
                            Copyrights © 2022 All rights reserved by
                            <a href="#">InquestPro</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "SiteFooter",
};
</script>

<style scoped lang="scss">
.bg-footer-custom {
    background: url("../../assets/img/footer-bg.png") no-repeat center top /
        cover !important;
}
</style>