<template>
    <section
        id="contact"
        class="contact-us ptb-100"
        v-bind:class="isGray ? 'gray-light-bg' : ''"
    >
        <div class="container">
            <div class="row">
                <div
                    class="col-12 pb-3 message-box d-done"
                    v-bind:class="{
                        'd-none': !isSuccess && !hasError,
                        'd-block': isSuccess || hasError,
                    }"
                >
                    <div
                        class="alert"
                        v-bind:class="{
                            'alert-danger': hasError,
                            'alert-success': isSuccess,
                        }"
                    >
                        {{ alertText }}
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="section-heading">
                        <h3>Contact with us</h3>
                        <p>
                            It's very easy to get in touch with us. Just use the contact form to send us any inquiries or to request access assistance to integrate InquestPro into your Cloud Jira instance.
                        </p>
                    </div>
                    <div class="footer-address">
                        <h6><strong>Head Office</strong></h6>
                        <p>EbizNeeds <br/>
                         S-23, Krishna Marg, Saket Nagar, Shyam Nagar, Jaipur, Rajasthan, India</p>
                        <ul>
                            <li>
                                <span
                                    >Email :
                                    <a href="php/sales.php"
                                        >&#115;&#97;&#108;&#101;&#115;&commat;&#105;&#110;&#113;&#117;&#101;&#115;&#116;&#112;&#114;&#111;&period;&#99;&#111;&#109;</a
                                    ></span
                                >
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-7">
                    <form
                        v-on:submit="submit"
                        id="contactForm"
                        class="contact-us-form"
                    >


                        <h5>Reach us quickly</h5>
                        <div class="row">
                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="name"
                                        v-model="name"
                                        placeholder="Enter name"
                                        required="required"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <input
                                        type="email"
                                        class="form-control"
                                        name="email"
                                        v-model="email"
                                        placeholder="Enter email"
                                        required="required"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        name="phone"
                                        value=""
                                        class="form-control"
                                        v-model="phone"
                                        placeholder="Your Phone"
                                        required="required"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        name="company"
                                        value=""
                                        size="40"
                                        class="form-control"
                                        v-model="company"
                                        placeholder="Your Company"
                                        required="required"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <textarea
                                        name="message"
                                        v-model="message"
                                        class="form-control"
                                        rows="7"
                                        cols="25"
                                        placeholder="Message"
                                        required="required"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <vue-recaptcha sitekey="6LdMkOgcAAAAADVlJhfrFLAOcseh-tPuZz5LFKgF"
                                                   ref="recaptcha"
                                                   @verify="verify" :loadRecaptchaScript="true"></vue-recaptcha>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 mt-3">
                                <button
                                    type="submit"
                                    class="btn solid-btn disabled"
                                    id="btnContactUs"
                                    style="pointer-events: all; cursor: pointer"
                                >
                                    Send Message
                                </button>

                            </div>
                        </div>
                    </form>
                    <p class="form-message"></p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
    name: "Contact",
    props: {
        isGray: {
            type: Boolean,
            default: false,
        },
    },
    components: { VueRecaptcha },
    data() {
        return {
            recaptcha: "",
            email: "",
            message: "",
            name: "",
            phone: "",
            company: "",
            alertText: "",
            hasError: false,
            isSuccess: false
        };
    },
    methods: {
        verify: function (response) {
            if (response) this.recaptcha = response;
        },
        submit: function (e) {
            e.preventDefault();
            if (this.recaptcha != "") {
                let formData = new FormData();
                formData.append("name", this.name);
                formData.append("message", this.message);
                formData.append("email", this.email);
                formData.append("phone", this.phone);
                formData.append("company", this.company);
                formData.append("recaptcha", this.recaptcha);
                fetch("/php/contact-form-process.php", {
                    body: formData,
                    method: "POST",
                    }).then((response) => {
                        if (response.status === 200) {
                            this.name = "";
                            this.message = "";
                            this.email = "";
                            this.phone = "";
                            this.company = "";
                            this.recaptcha = "";
                            this.alertText = "Form submitted successfully";
                            this.hasError = false;
                            this.isSuccess = true;
                        } else {
                            this.alertText =
                                "Found error in the form. Please check again.";
                            this.isSuccess = false;
                            this.hasError = true;
                        }
                        window.grecaptcha.reset()
                    })
                    .catch((error) => {
                        console.log(error);
                        this.alertText =
                            "Found error in the form. Please check again.";
                        this.isSuccess = false;
                        this.hasError = true;
                        window.grecaptcha.reset()
                    });


            }
        },

        mounted() {
            this.alertText = "";
            this.hasError = false;
            this.isSuccess = false;

        },
    },
};
</script>

<style>
</style>