<template>
    <section id="features" class="feature-section ptb-100">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-5">
                    <div class="download-img">
                        <img
                            src="img/image-10.png"
                            alt="test plan"
                            class="img-fluid"
                        />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="feature-contents section-heading">
                        <h2>
                            Why choose InquestPro  <br />
                            as your test case management solution?
                            
                        </h2>
                        <p> 
                            Writing down test cases is only the first step. Test cases need to be organized, planned and their results tracked accordingly. 
                        </p>
                        <p>
                            InquestPro can help you solve this problem and simplify your testing process.
                        </p>
                        
                        <div class="row feature-content-wrap justify-content-center">
                            <ul
                                class="nav nav-tabs feature-tab"
                                data-tabs="tabs"
                            >
                                <li class="nav-item">
                                    <a
                                        class="nav-link h6"
                                        href="#"
                                        @click.prevent="toggleVisiblity(0)"
                                        data-toggle="tab"
                                        v-bind:class="{ active: tabVisible[0] }"
                                    >
                                        <span class="ti-file"></span>
                                        Test Case
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        class="nav-link h6"
                                        href="#"
                                        @click.prevent="toggleVisiblity(1)"
                                        v-bind:class="{ active: tabVisible[1] }"
                                    >
                                        <span class="ti-filter"></span>
                                        Test Cycle
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        class="nav-link h6"
                                        href="#"
                                        @click.prevent="toggleVisiblity(2)"
                                        v-bind:class="{ active: tabVisible[2] }"
                                    >
                                        <span class="ti-announcement"></span>
                                        Test Plan
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        class="nav-link h6"
                                        href="#"
                                        @click.prevent="toggleVisiblity(3)"
                                        v-bind:class="{ active: tabVisible[3] }"
                                    >
                                        <span class="ti-bar-chart"></span>
                                        Reporting
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content feature-tab-content">
                                <div
                                    v-show="tabVisible[0]"
                                    class="tab-pane active"
                                >
                                    <div class="single-feature">
                                        <div
                                            class="d-flex align-items-center mb-2"
                                        >
                                            <span
                                                class="ti-layers rounded mr-3 icon icon-color-1"
                                            ></span>
                                            <h5 class="mb-0">Easy to use</h5>
                                        </div>
                                        <p>
                                            Intuitive UI for test case creation in standard or BDD format.
                                            Compare versions and reuse test cases with End-to-end traceability.
                                            

                                        </p>
                                    </div>
                                    
                                </div>
                                <div
                                    v-show="tabVisible[1]"
                                    class="tab-pane active"
                                >
                                    <div class="single-feature">
                                        <div
                                            class="d-flex align-items-center mb-2"
                                        >
                                            <span
                                                class="ti-stats-up rounded mr-3 icon icon-color-3"
                                            ></span>
                                            <h5 class="mb-0">Traceability</h5>
                                        </div>
                                        <p>
                                            Group test cases to achieve specific testing goals. 
                                            Simple to navigate nested folder structure, inculding attachments, weblinks and history.

                                        </p>
                                    </div>
                                    
                                </div>
                                <div
                                    v-show="tabVisible[2]"
                                    class="tab-pane active"
                                >
                                    
                                    <div class="single-feature mb-4">
                                        <div
                                            class="d-flex align-items-center mb-2"
                                        >
                                            <span
                                                class="ti-alarm-clock rounded mr-3 icon icon-color-2"
                                            ></span>
                                            <h5 class="mb-0">
                                                Management
                                            </h5>
                                        </div>
                                        <p>
                                            The ultimate guide for QA engineers to conduct their testing activities.
                                            Consolidated view for test cases for a better way to manage manage your growing test library.

                                        </p>
                                    </div>
                                </div>
                                <div
                                    v-show="tabVisible[3]"
                                    class="tab-pane active"
                                >
                                    <div class="single-feature mb-4">
                                        <div
                                            class="d-flex align-items-center mb-2"
                                        >
                                            <span
                                                class="ti-bar-chart rounded mr-3 icon icon-color-2"
                                            ></span>
                                            <h5 class="mb-0">
                                                Observability
                                            </h5>
                                        </div>
                                        <p>
                                            Reports ensure you always know where you are in your testing cycle. 
                                            Flexible cross project reports.
                                            Test Execution, Traceability and Live Statistics.
                                            Export and share in PDF and Excel.

                                        </p>
                                    </div>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Vue from "vue";

export default {
    name: "Features",
    data() {
        return {
            tabVisible: [true, false, false, false],
        };
    },
    methods: {
        toggleVisiblity(index) {
            console.log("here");
            for (let i = 0; i < this.tabVisible.length; i++) {
                console.log("start " + i);
                if (i === index) Vue.set(this.tabVisible, i, true);
                else Vue.set(this.tabVisible, i, false);
                console.log("end " + i);
            }
        },
    },
};
</script>

<style>
</style>