<template>
    <div>
        <nav-bar />
        <div class="main">
            <banner />
            <promo />
            <about />
            <features />
            <video-promo />
            <contact :is-gray="true" />
            <wave :is-gray="true" />
            <site-footer />
        </div>
    </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import Banner from "../../views/index-four/Banner";
import Promo from "../../views/index-four/Promo";
import About from "../../views/index-four/About";
import Features from "../../views/index-four/Features";

import VideoPromo from "../../views/index-four/VideoPromo";
import Contact from "../../views/commons/Contact";
import Wave from "../../views/commons/Wave";
import SiteFooter from "../../views/commons/SiteFooter";

export default {
    name: "IndexFour",
    components: {
        NavBar,
        Banner,
        Promo,
        VideoPromo,
        About,
        Features,
        Contact,
        Wave,
        SiteFooter,
    },
};
</script>

<style>
</style>