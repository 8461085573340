<template>
    <section id="about" class="about-us ptb-100 gray-light-bg">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-7">
                    <div class="about-content-left section-heading">
                        <h2>
                            An enterprise test app purpose built for Jira cloud.
                        </h2>

                        <div class="single-feature mb-4">
                            <div
                                class="icon-box-wrap d-flex align-items-center mb-2"
                            >
                                <div class="mr-3 icon-box">
                                    <img
                                        src="img/icons8-rocket.gif"
                                        alt="icon image"
                                        class="img-fluid"
                                    />
                                </div>
                                <p class="mb-0">
                                    Accelerate your teams ability to plan, manage and track testing inside Jira.
                                </p>
                            </div>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img
                                        src="img/icons8-wrench.gif"
                                        alt="icon image"
                                        class="img-fluid"
                                    />
                                </div>
                                <p class="mb-0">
                                    Built for teams who need a better way manage their growing test library. Organise, share and reuse test cases across Jira projects.

                                </p>
                            </div>
                            <p></p>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img
                                        src="img/icons8-add-to-cloud.gif"
                                        alt="icon image"
                                        class="img-fluid"
                                    />
                                </div>
                                <p class="mb-0">
                                    Data Residency enabled, InquestPro is engineered to perform at scale in the cloud while keeping your data secure.
                                </p>
                            </div>
                            <p></p>
                        </div>
                        <div class="single-feature mb-4">
                            <div class="icon-box-wrap mb-2">
                                <div class="mr-3 icon-box">
                                    <img
                                        src="img/icons8-gears.gif"
                                        alt="icon image"
                                        class="img-fluid"
                                    />
                                </div>
                                <p class="mb-0">
                                    Test Execution, Traceability and Live Statistics. Export and share in PDF and Excel

                                </p>
                            </div>
                            <p></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="about-content-right">
                        <img
                            src="img/image-14.png"
                            alt="about us"
                            class="img-fluid"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "About",
};
</script>

<style>
</style>