<template>
    <div>
        <nav-bar />
        <div class="main">
            <banner />
            <policy  />
            <site-footer />
        </div>
    </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import Banner from "../../views/terms/BannerPolicy";
import Policy from "../../views/terms/Policy";
import SiteFooter from "../../views/commons/SiteFooter";

export default {
    name: "PrivacyPolicy",
    components: {
        NavBar,
        Banner,
        Policy,
        SiteFooter,
    },
};
</script>

