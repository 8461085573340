<template>
    <section class="promo-section ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-8">
                    <div class="section-heading text-center mb-5">
                        <h2>
                            Why test teams <br />love InquestPro?
                        </h2>
                        <p class="lead">
                            Following reasons show advantages of adding <strong>InquestPro</strong> to
                            your testing strategy:
                        </p>
                    </div>
                </div>
            </div>
            <div class="row equal">
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                    >
                        <div class="circle-icon mb-5">
                            <span class="ti-vector text-white"></span>
                        </div>
                        <h5>Clear Workflows</h5>
                        <p>
                            Lightweight test planning by design increases team performance.
                        </p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                    >
                        <div class="circle-icon mb-5">
                            <span class="ti-lock text-white"></span>
                        </div>
                        <h5>Secure Data</h5>
                        <p>
                            CLoud based platform for your peace of mind.
                        </p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100"
                    >
                        <div class="circle-icon mb-5">
                            <span class="ti-eye text-white"></span>
                        </div>
                        <h5>End-to-end visibility</h5>
                        <p>
                            Real-time project monitoring allowing validation and informed release decisions.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "Promo",
};
</script>