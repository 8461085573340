<template>
    <section class="team-member-section ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-12">
                    <div class="section-heading mb-4">
                        <h3 class="text-center">Overview</h3>
                        <p class="lead">
                            Protecting your data and your privacy is a high priority and is very important to us. eBizneeds, adhere to a strict policy for ensuring the security and privacy of your data, in particular, your personally identifiable information (such as name, address, email address, and/or other identifiable information).
                        </p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-12">
                    <div class="feature-contents section-heading post-content">
                        <p>
                            We provide Cloud Apps for Atlassian Cloud Products via the Atlassian Marketplace (<a href="https://marketplace.atlassian.com/">https://marketplace.atlassian.com/</a>). The apps are delivered through the Atlassian Connect framework (“Atlassian Connect”). Cloud Apps can be identified by the “Cloud” category in the corresponding Atlassian Marketplace listing.
                        </p>
                        <p>
                            This Privacy Policy provides you with an overview of the collection and processing of your data. By using, downloading, or visiting any of our services, you are accepting and consenting to the practices outlined in this Policy.
                        </p>
                        <p class="font-weight-bolder">
                            If you do not agree with this policy, do not access or use our Services or interact with any other aspect of our business.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-12">
                    <div class="post-content">
                        <h5>Information We May Collect From You</h5>
                        <p>
                            We may collect information from you because we have a legal reason (allowed by law or under contract) to collect the information, or because You have consented for us to do so for a specific purpose.
                        </p>
                        <h5>Information You Give Us</h5>
                        <p>
                            You may give us information with your consent, for example:
                        </p>
                        <ol>
                            <li>By filling in a form or sending us an e-mail;</li>
                            <li>By contacting us via e-mail or some other medium to request service or support (e.g., Jira Service Desk);</li>
                            <li>Installing and using our Apps.</li>
                        </ol>
                        
                        <h5>Information We May Collect From You</h5>
                        <p>
                            If you visit our Website, we may automatically collect information about you, for example:
                        </p>
                        
                        <ol>
                            <li>Technical information;</li>
                            <li>IP addresses;</li>
                            <li>Information about what type of device You use to connect to our Website or Services; and</li>
                            <li>The manner in which you interact with our services.</li>
                            
                        </ol>
                        <h5>Personally identifiable information (PII)</h5>
                        <p>
                            We do not transfer or store PII data on our servers.
                        </p>
                        <h5>Information received from the Atlassian Marketplace</h5>
                        <p>
                            As part of Our products and Services, we have access to the contact information (e.g., your name, phone number, e-mail address, any physical address) and license information (e.g., number of licenses, term of the license, etc.) you provide when you subscribe to a license for any of our Services or when you renew maintenance for our services on the Atlassian Marketplace. We do not have any access to customer data from the Atlassian Marketplace beyond this.
                        </p>

                        <h5>Where we store our data</h5>
                        <p>
                            We are leveraging Amazon Web Services to store our data. According to AWS GDPR center - AWS has announced compliance with the CISPE Code of Conduct and demonstrating compliance with rigorous international standards, such as ISO 27017 for cloud security, ISO 27018 for cloud privacy, SOC 1, SOC 2 and SOC 3, PCI DSS Level 1 and others. Your data on AWS used for processing The Scheduler scheduled jobs is secured in highest manner possible. We are not sharing or exposing those data to any 3rd party legal entity or partners.
                        </p>
                        <h5>What Jira information we collect</h5>
                        <p>
                            We are collecting data pulled from your Jira cloud instance that is essential for the purposes of linking your test data with your Jira Issue/s such as unique ID, name, description and associated JIRA project ID and project key. We do not store your business-confidential information on our servers at any time.
                        </p>
                        <h5>Removing InquestPro and all the associated data</h5>
                        <p>
                            At any time, you have an option to remove the InquestPro add-on from your JIRA instance along with all the data stored by our add-on. In order to do that simply uninstall the add-on using the "Manage add-ons" via the administration section – this will remove the add-on from your JIRA cloud instance and also remove all your data from our servers.
                        </p>
                        <h5>People and Access</h5>
                        <p>
                            The InquestPro support team accesses hosted data only for purposes of application health monitoring and performing system or application maintenance, as well as upon customer request for support purposes. Only authorized employees have access to server data. Cloud users are authenticated using Atlassian Connect OAuth authentication. Customers are responsible for maintaining the security of their own JIRA Cloud login information.
                        </p>
                        <h5>Changes to this policy</h5>
                        <p>
                            Changes to this policy may be introduced at any time. Please check this page regularly to be informed about the changes.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Policy",
};
</script>

<style>
</style>