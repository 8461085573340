var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"header"},[_c('nav',{staticClass:"navbar navbar-expand-lg fixed-top",class:{
            affix: _vm.hasAffix,
            'custom-nav': _vm.coloredLogo,
            'bg-transparent': !_vm.coloredLogo,
            'white-bg': _vm.coloredLogo,
        }},[_c('div',{staticClass:"container"},[_c('a',{staticClass:"navbar-brand",attrs:{"href":"/"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.coloredLogo
                            ? 'img/logo-color-1x.png'
                            : 'img/logo-white-1x.png',"width":"180","alt":"logo"}})]),_c('button',{staticClass:"navbar-toggler",class:{ collapsed: _vm.collapsed },attrs:{"type":"button"},on:{"click":_vm.mobileNavClicked}},[_c('span',{staticClass:"ti-menu"})]),_c('div',{staticClass:"collapse navbar-collapse main-menu h-auto",class:{ show: !_vm.collapsed },attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav ml-auto"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link page-scroll",attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#about'),expression:"'#about'"}],staticClass:"nav-link page-scroll",attrs:{"to":{ name: 'home', hash: '#about' }}},[_vm._v("About")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#features'),expression:"'#features'"}],staticClass:"nav-link page-scroll",attrs:{"to":{ name: 'home', hash: '#features' }}},[_vm._v("Features")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#contact'),expression:"'#contact'"}],staticClass:"nav-link page-scroll",attrs:{"to":{ name: 'home', hash: '#contact' }}},[_vm._v("Contact")])],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }