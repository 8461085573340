<template>
    <div>
        <nav-bar />
        <div class="main">
            <banner />
            <service  />
            <site-footer />
        </div>
    </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import Banner from "../../views/terms/BannerService";
import Service from "../../views/terms/Service";
import SiteFooter from "../../views/commons/SiteFooter";

export default {
    name: "Eula",
    components: {
        NavBar,
        Banner,
        Service,
        SiteFooter,
    },
};
</script>

