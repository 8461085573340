<template>
    <section
            class="shape-img subscribe-wrap"
            id="wave"
            v-bind:class="isGray ? 'gray-light-bg' : ''"
    >
            <img
                    alt="footer shape"
                    class="img-fluid"
                    src="img/footer-top-shape.png"

            />

        </section>
</template>


<script>
    export default {
        name: "Wave",
        props: {
            isGray: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {}
        },
        methods: { },
    };
</script>