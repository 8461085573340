<template>
    <section class="team-member-section ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-12">
                    <div class="section-heading mb-4">
                        
                        <p class="lead">
                            This End-User License Agreement (EULA) is a legal agreement between you (either as an individual or single entity) and eBizneeds Business Solutions Pvt. Ltd. By installing, copying or using any of the InquestPro apps for the Atlassian platform, you agree to be bound by the terms of this EULA.
                        </p>
                    </div>
                </div>
            </div>
            
            <div class="row justify-content-center">
                <div class="col-lg-10 col-md-12">
                    <div class="post-content">
                        <h5>1. LICENSE</h5>
                        <p>
                            The END USER LICENSE AGREEMENT (hereinafter referred to as EULA) is a legal agreement between you (either an individual or a single entity) and eBizneeds Business Solutions Pvt. Ltd (hereinafter referred to as eBizneeds) for the InquestPro– Test Management for Jira (hereinafter referred to as the Software). By receiving, opening the file package, and/or using InquestPro – Test Management for Jira (“Software”) containing this software, you agree that this EULA is a legally binding and valid contract and agree to be bound by it. You agree to abide by the intellectual property laws and all of the terms and conditions of this Agreement.
                        </p>
                        <p>
                            Unless you have a different license agreement signed by eBizneeds your use of the Software indicates your acceptance of this license agreement and warranty.
                        </p>
                        <p>
                            Subject to the terms of this Agreement, eBizneeds grants to you a limited, non-exclusive, non-transferable license, without right to sub-license, to use the Software in accordance with this Agreement and any other written agreement with eBizneeds. eBizneeds does not transfer the title of the Software to you; the license granted to you is not a sale. This agreement is a binding legal agreement between eBizneeds and the purchasers or users of InquestPro – Test Management for Jira.
                        <p class="font-weight-bolder">    
                            If you do not agree to be bound by this agreement, remove the Software for Jira from all of your Atlassian Jira installations.
                        </p>
                        <h5>2. DISTRIBUTION</h5>
                        <p>
                            InquestPro - Test Management for Jira the license herein granted shall not be copied, shared, distributed, re-sold, offered for re-sale, transferred or sub-licensed in whole or in part. For information about redistribution of the Software contact eBizneeds.
                        </p>
                        <h5>3. USER AGREEMENT</h5>
                        <span class="font-weight-bolder">
                           3.1 Use
                        </span>
                        <p>
                            Your license to use the Software is limited to the number of licenses purchased by you. You shall not allow others to use, copy or evaluate copies of the Software.
                        </p>
                        <span class="font-weight-bolder">
                            3.2 Use Restrictions
                        </span>
                        <p>
                            You shall use InquestPro - Test Management for Jira in compliance with all applicable laws and not for any unlawful purpose. Without limiting the foregoing, use, display or distribution of the Software together with material that is pornographic, racist, vulgar, obscene, defamatory, libelous, abusive, promoting hatred, discriminating or displaying prejudice based on religion, ethnic heritage, race, sexual orientation or age is strictly prohibited.
                        </p>
                        <span class="font-weight-bolder">
                            3.3 Copyright Restriction
                        </span>
                        <p>
                            This Software contains copyrighted material, trade secrets and other proprietary material. You shall not, and shall not attempt to, modify, reverse engineer, disassemble or decompile the Software. Nor can you create any derivative works or other works that are based upon or derived from the Software in whole or in part. eBizneeds’s name, logo and graphics file that represents the Software shall not be used in any way to promote products developed with the Software. eBizneeds retains sole and exclusive ownership of all right, title and interest in and to the Software and all Intellectual Property rights relating thereto. Copyright law and international copyright treaty provisions protect all parts of the Software, products and services. No program, code, part, image, audio sample, or text may be copied or used in any way by the user except as intended within the bounds of the single user program. All rights not expressly granted hereunder are reserved for eBizneeds.
                        </p>
                        <span class="font-weight-bolder">
                            3.4 Limitation of Responsibility
                        </span>
                        <p>
                            You will indemnify, hold harmless, and defend eBizneeds, its employees, agents and distributors against any and all claims, proceedings, demand and costs resulting from or in any way connected with your use of eBizneeds’s Software. In no event (including, without limitation, in the event of negligence) will eBizneeds, its employees, agents or distributors be liable for any consequential, incidental, indirect, special or punitive damages whatsoever (including, without limitation, damages for loss of profits, loss of use, business interruption, loss of information or data, or pecuniary loss), in connection with or arising out of or related to this Agreement, the Software or the use or inability to use the Software or the furnishing, performance or use of any other matters hereunder whether based upon contract, tort or any other theory including negligence.
                        </p>
                        <span class="font-weight-bolder">
                            3.5 Warranties
                        </span>
                        <p>
                            Except as expressly stated in writing, eBizneeds makes no representation or warranties in respect of this Software and expressly excludes all other warranties, expressed or implied, oral or written, including, without limitation, any implied warranties of merchantable quality or fitness for a particular purpose.
                        </p>
                        <span class="font-weight-bolder">
                            3.6 Governing Law
                        </span>
                        <p>
                            This Agreement shall be governed by the laws of England and Wales applicable therein. You hereby irrevocably attorn and submit to the non-exclusive jurisdiction of the courts of England and Wales therefrom. If any provision shall be considered unlawful, void or otherwise unenforceable, then that provision shall be deemed severable from this license and not affect the validity and enforceability of any other provisions.
                        </p>
                        <span class="font-weight-bolder">
                            3.7 Termination
                        </span>
                        <p>
                            Any failure to comply with the terms and conditions of this Agreement will result in automatic and immediate termination of this license. Upon termination of this license granted herein for any reason, you agree to immediately cease use of InquestPro - Test Management for Jira and destroy all copies of the Software supplied under this Agreement. The financial obligations incurred by you shall survive the expiration or termination of this license.
                        </p>
                        <h5>4. DISCLAIMER OF WARRANTY</h5>
                        <p>
                            THIS SOFTWARE AND THE ACCOMPANYING FILES ARE SOLD “AS IS” AND WITHOUT WARRANTIES AS TO PERFORMANCE OR MERCHANTABILITY OR ANY OTHER WARRANTIES WHETHER EXPRESSED OR IMPLIED. THIS DISCLAIMER CONCERNS ALL FILES GENERATED AND EDITED BY InquestPro – Test Management for Jira AS WELL.
                        </p>
                        <p class="text-right">
                            Last updated: April 2022
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Service",
};
</script>

<style>
</style>